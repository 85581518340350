









































































































import { Vue, Component } from 'vue-property-decorator';
import DialogContact from '@/components/3-dialogs/dialog-contact.vue'; /* KJ: Wordt helemaal niet gebruikt! Why? */

@Component({
  components: { DialogContact },
})
export default class ContactView extends Vue {
  public form = {
    name: '',
    company: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  public get phoneNumber() {
    return webpack.CONFIG.admin.phone;
  }

  public get emailAddress() {
    return webpack.CONFIG.admin.email;
  }

  public async created() {
    await this.$user.ready;
    if (!this.$user.data) return;

    this.form = {
      ...this.form,
      email: this.$user.data.email,
      name: this.$user.data.name.display,
      company: this.$company.data ? this.$company.data.name : '',
    };
  }

  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public error() {
    this.$bce.message(this.$t('message-not-sent'), 2);
  }

  public async submit() {
    const form = this.$el.querySelector('bce-form')!;

    await this.$firebase.api.mail({ ...this.form });
    this.$bce.message(this.$t('message-sent'), 2);
    await form.reset();
    this.$router.push('/');
  }

  public onMail() {
    location.href = `mailto:${this.emailAddress}`;
  }

  public onPhone() {
    location.href = `tel:${this.phoneNumber}`;
  }

  public onWhatsapp() {
    window.open(`https://api.whatsapp.com/send?phone=${this.phoneNumber}`);
  }
}
